
import React, { useEffect, useState } from 'react'
import s from './panelRight.module.css'
import { ISensor } from '../../app/interface'
import hot from './../../img/CBKG.png'
import cold from './../../img/CBKX.png'
import heat from './../../img/Group 66.png'
import { TfiLocationPin } from "react-icons/tfi"
import { TfiClose } from "react-icons/tfi"
import { TfiInfoAlt } from "react-icons/tfi"
import { RiAlertFill } from "react-icons/ri"
import { TfiTime } from "react-icons/tfi"
import MessageRP from './MessageRP'
import PrevRP from './PrevRP'
import Charts from './charts/Charts'
import { useGetSensorMutation } from '../../app/slices/sliceLogApi'
import { useAppSelector } from '../../app/Hooks'
import { useDispatch } from 'react-redux'
import { errorsReducer, loadingReducer } from '../../app/slices/pagesSlice'
import { setPlaceIdReducer } from '../../app/slices/reportSlice'



type Props = {
  close: () => void

}
const PanelRight = ({ close }: Props) => {
  const token = useAppSelector(state=> state.login.token)
  const dispatch = useDispatch()
  const { id, resource, placeId } = useAppSelector(state => state.report)
  const [getSensor, {isLoading, isError}] = useGetSensorMutation()
  const [sensor, setSensor] = useState<ISensor>({
    data: {
      sensor_number: '',
      account: '',
      network_address: '',
      device_name: '',
      external_id: '',
      sensor_date_first: null,
      sensor_date_next: null,
      sensor_date_install: null,
      sensor_manufacturer: null,
      check_date: '',
      messages: [
        {
          date:'',
          message:''

        }
      ],
      parameter_id: '',
      address: [{
        place_name: '',
        place_id: '',
        place_type_name: '',
      }],
      sensor_parameter: '',
      unit:'',
      last_seen: '',
      last_value: 0,
      bat: 0,
      resource: '',
      status: '',
      entity: ''
    },

  })
  const [page, setPage] = useState<number>(0)
  const item = [undefined, <MessageRP />, <PrevRP />]
  const [color, setColor] = useState('#39cc64')
  const [show, setShow] = useState(true)

  useEffect(() => {
    dispatch(errorsReducer(isError))
  }, [isError, dispatch])
  useEffect(() => {
    dispatch(loadingReducer(isLoading))
  }, [isLoading, dispatch])
  useEffect(() => {
    getSensor({ token, id }).unwrap()
      .then(res => {

        setSensor(res)
        if (res.data.bat > 60) setColor('#39cc64')
        if (res.data.bat <= 60 && res.data.bat > 20) setColor('orange')
        if (res.data.bat <= 20) setColor('red')
        dispatch(setPlaceIdReducer(res.data.address[res.data.address.length -1].place_id))
      })
  }, [getSensor, id, token, dispatch])

  const hidden = () => {
    setShow(false)
    setTimeout(() => {
      close()
    }, 1100)
  }
  
  

  let d = ''
  sensor.data.address.forEach((v, index) => {
    if (v.place_type_name === "Область") d = d + ('обл.' + v.place_name + ', ')
    if (v.place_type_name === "Город") d = d + ('г.' + v.place_name)
    if (v.place_type_name === "Улица") d = d + (', ул.' + v.place_name)
    if (v.place_type_name === "Проспект") d = d + (', пр-т.' + v.place_name)
    if (v.place_type_name === "Переулок") d = d + (', пер.' + v.place_name)
    if (v.place_type_name === "Бульвар") d = d + (', б-р.' + v.place_name)
    if (v.place_type_name === "Дом") d = d + (', д.' + v.place_name)
    if (v.place_type_name === "Квартира") d = d + (', кв.' + v.place_name)
  })
  let b = ['СВКХ-15к', 'blue', 'Холодная вода']
  if (sensor.data.resource === 'cold') b = ['СВКХ-15к','blue', 'Холодная вода', cold]
  if (sensor.data.resource === 'hot') b = ['СВКГ-15к',  '#f03737', 'Горячая вода', hot]
  if (sensor.data.resource === 'heat') b = ['ТС',   '#FFA500', 'Тепло', heat]
  return (
    <div className={show ? s.panelRight : s.panelRightHidden}>
      <div className={s.header}>
        <div className={s.headerLeft}>
          <TfiLocationPin className={s.iconLoc} />
          <div className={s.headerTitle}>
            <span className={s.titleAddress}>{d}</span>
          </div>
        </div>

        <TfiClose className={s.iconClose} onClick={hidden} />

      </div>
      <div className={s.btn}>
        <div className={(page !== 0) ? s.btnInfo : s.btnInfoActive} onClick={() => { setPage(0) }}>
          <TfiInfoAlt className={s.btnIcon} />
          <span className={s.txtBtn}>Информация</span>
        </div>
        <div className={(page !== 1) ? s.btnInfo : s.btnInfoActive} onClick={() => { setPage(1) }}>
          <RiAlertFill className={s.btnIcon} />
          <span className={s.txtBtn}>Уведомления</span>
        </div>
        <div className={(page !== 2) ? s.btnInfo : s.btnInfoActive} onClick={() => { setPage(2) }}>
          <TfiTime className={s.btnIcon} />
          <span className={s.txtBtn}> Архив</span>
        </div>
      </div>

      
      <div className={s.wrapper}>
        {page ? item[page] :
          <div className={s.sectionInfo}>

            <div className={s.description}>

              <div className={s.sectionPic}>
                <img className={s.water} src={b[3]} alt="pic" />
              </div>

              <div className={s.sctionDesc}>

              {/*  <div className={s.desc}>
                  <p className={s.t2}>ID первичного прибора:</p>
                  <p className={s.t2}>{sensor.data.parameter_id}</p>
                </div>*/}
                <div className={s.desc}>
                  <p className={s.t2}>Производитель:</p>
                  <p className={s.t2}>{sensor.data.sensor_manufacturer}</p>
                </div>

                <div className={s.desc}>
                  <p className={s.t1}>Марка прибора:</p>
                  <p className={s.t1} style={{ color: b[1] }} ></p>
                </div>
                <div className={s.desc}>
                  <p className={s.t1}>Тип ресурса:</p>
                  <p className={s.t1} style={{ color: b[1] }} >{b[2]}</p>
                </div>
                <div className={s.desc}>
                  <p className={s.t1}>Номер счетчика: </p>
                  <p className={s.t1}>{sensor.data.sensor_number}</p>
                </div>
                <div className={s.desc}>
                  <p className={s.t1}>Номер радиомодуля: </p>
                  <p className={s.t1}></p>
                </div>
                <div className={s.desc}>
                  <p className={s.t1}>Тип радиомодуля: </p>
                  <p className={s.t1}>{sensor.data.device_name}</p>
                </div>
                <div className={s.desc}>
                  <p className={s.t2}>Дата получения данных:</p>
                  <p className={s.t2}>{sensor.data.last_seen && sensor.data.last_seen.split('T')[0]}</p>
                </div>

                <div className={s.desc}>
                  <p className={s.t2}>Последнее показание:</p>
                  <p className={s.t2}>{sensor.data.last_value}</p>
                </div>

                <div className={s.desc}>
                  <p className={s.t2}>Единица измерения:</p>
                  <p className={s.t2}>{sensor.data.unit}</p>
                </div>
                <div className={s.desc}>
                  <p className={s.t2}>Лицевой счет:</p>
                  <p className={s.t2}>{sensor.data.account}</p>
                </div>
                <div className={s.desc}>
                  <p className={s.t2}>Внешний ID прибора:</p>
                  <p className={s.t2}>{sensor.data.external_id}</p>
                </div>
                <div className={s.desc}>
                  <p className={s.t2}>Последняя поверка:</p>
                  <p className={s.t2}>{!sensor.data.sensor_date_first ? sensor.data.sensor_date_first :
                    sensor.data.sensor_date_first.split('T')[0]}</p>
                </div>
                <div className={s.desc}>
                  <p className={s.t2}>Следующая поверка:</p>
                  <p className={s.t2}>{!sensor.data.sensor_date_next ? sensor.data.sensor_date_next :
                    sensor.data.sensor_date_next.split('T')[0]}</p>
                </div>

              </div>
            </div>

            <div className={s.battery}>
              <div className={s.batteryText}>
                <p className={s.batteryTxt}>Батарея</p>
                <p className={s.batteryNum} style={{ color: color }}>{sensor.data.bat}%</p>
              </div>
              <div className={s.batteryPic}>
                <div className={s.batteryLine} style={{ backgroundColor: color, width: `${sensor.data.bat}%` }}>
                </div>
              </div>
            </div>

            <div className={s.chart}>
              {placeId.length > 0 && <Charts id={placeId} resource={resource} />}

            </div>

          </div>}

      </div>




    </div>


  )
}

export default PanelRight