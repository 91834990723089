import React, { useState } from 'react'
import s from './navBar.module.css'
import { TfiAlignJustify } from "react-icons/tfi";
import { TfiHome } from "react-icons/tfi";
import { HiWifi } from "react-icons/hi2";
import { TfiLocationPin } from "react-icons/tfi";
import { TfiClipboard } from "react-icons/tfi";
import { TfiUser } from "react-icons/tfi";
import { TfiExport } from "react-icons/tfi";
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import { clearTokenReducer } from '../app/slices/loginSlice';
import { activePageReducer } from '../app/slices/pagesSlice';
type Props = {
  resolve?: boolean
}

const NavBar = ({ resolve }: Props) => {

  const dispatch = useAppDispatch()
  const name = useAppSelector(state => state.login.user.firstname)
  const activePage = useAppSelector(state => state.pages.activePage)
  const exit = () => {
    dispatch(clearTokenReducer())
  }
  const [hidden, setHidden] = useState(true)
  const pages = ['Главная', 'Приборы', 'Адреса', 'Отчеты']
  const icons = [<TfiHome className={s.icon} />, <HiWifi className={s.icon} />,
  <TfiLocationPin className={s.icon} />, <TfiClipboard className={s.icon} />]


  const onClick = (index: number) => {
    dispatch(activePageReducer(index))
  }

  const menuMini: React.JSX.Element[] = pages.map((el, i) => {
    return (
      <div key={el + i} className={s.iconItem} onClick={() => { onClick(i) }}>
        <div style={(i === activePage) ? { backgroundColor: '#E7EAEF' } : undefined} className={s.aktiv} ></div>
        {icons[i]}

      </div>
    )
  })
  const menu = pages.map((el, i) => {
    return (
      <div key={el + i + 'mini'} className={s.iconItem} onClick={() => { onClick(i) }}>
        <p className={s.iconTitle}>{el}</p>
      </div>
    )
  })


  return (
    <div className={s.navBar}>
      <div className={s.navBarMini} >

        <div className={s.iconsBlock}>

          <div className={s.iconItem} style={resolve? {cursor: 'default'}: undefined} >
            <div className={s.aktiv} ></div>
            <TfiAlignJustify className={!resolve ? s.icon : s.icon2} onClick={() => {
              if (!resolve) setHidden(!hidden)
            }} />
          </div>

          {menuMini}

        </div>
        <div className={s.iconBottomMini}>

          <div className={s.iconName}>
            <TfiUser className={s.icon} />
          </div>

          <div className={s.iconExit} onClick={exit}>
            <TfiExport className={s.iconEx} />
          </div>

        </div>
      </div>

      <div className={!hidden ? s.navBarShow : s.navBarHidden} >


        <div className={s.iconsBlock}>
          <div className={s.iconItem} >
          </div>

          {menu}

        </div>

        <div className={s.iconBottom}>
          <div className={s.iconName}>
            <p className={s.iconTitle}>{name}</p>
          </div>

          <div className={s.iconExit} onClick={exit}>
            <p className={s.iconTitleExit}>Выход</p>
          </div>

        </div>
      </div>
    </div>

  )
}

export default NavBar