
import { IUser } from '../interface';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IUser = {
    user: {
        email: null,
        firstname: null,
      },
      token: '',
      expires: undefined,
    
    }

export const loginSlice = createSlice({

    name: 'login',
    initialState,
    reducers: {
        tokenReducer: (state, action: PayloadAction<IUser>) => {
            state.user = action.payload.user
            if (action.payload.token) {
                state.token = action.payload.token
                localStorage.setItem('app_premex', action.payload.token)
            }
        },
        clearTokenReducer: (state) => {
            state.token = null
            state.user.email = null
            localStorage.removeItem('app_premex')
        },
    
    },
}

)
export const { tokenReducer, clearTokenReducer } = loginSlice.actions;
export default loginSlice.reducer;