
import React, { useLayoutEffect, useState } from 'react'
import s from './pages.module.css'
import { VscCircleLargeFilled } from "react-icons/vsc";
import { RiAlertFill } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import PanelRight from './PanelRight';
import NumberPages from './NumberPages';
import { TfiFilter } from "react-icons/tfi";
import { getIdReducer } from '../../app/slices/reportSlice';
type Props = {
  isloader: boolean
  showFilter: React.Dispatch<React.SetStateAction<boolean>>
}

const FirstPages = ({ isloader, showFilter }: Props) => {
  const data = useAppSelector(state => state.data.data)
  const [showPanel, setshowPanel] = useState<number | undefined>(undefined)
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    showFilter(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const table = data.data.map((el, i) => {
    let a = <VscCircleLargeFilled className={s.t00} size={10} />
    let e = <p className={s.prompt}></p>
    if (el.status === 'online') {
      a = <VscCircleLargeFilled color={'#39cc64'} className={s.t00} size={10} />
      e = <div className={s.prompt} >
        <div className={s.promptText}>В сети</div></div>
    }
    if (el.status === 'offline') {
      a = <VscCircleLargeFilled color={'#FFFF00'} className={s.t00} size={10} />
      e = <div className={s.prompt}>
        <div className={s.promptText}>Не в сети</div></div>
    }
    if (el.status === 'error') {
      a = <RiAlertFill color={'#f03737'} className={s.t00} size={16} />
      e = <div className={s.prompt}>
        <div className={s.promptText}> Ошибка</div></div>
    }
    if (el.status === 'warning') {
      a = <RiAlertFill color={'#FF8C00'} className={s.t00} size={16} />
      e = <div className={s.prompt}>
        <div className={s.promptText}>Нет связи </div>
        <div className={s.promptTextT}>48 часов</div> </div>
    }
    let b = ['СВХ', 'blue']
    if (el.resource === 'cold') b = ['СВКХ-15к', 'blue']
    if (el.resource === 'hot') b = ['СВКГ-15к', '#f03737']
    if (el.resource === 'heat') b = ['ТС', '#FFA500']
    let c = ''
    if (el.entity === 'individual') c = 'Физическое лицo'
    if (el.entity === 'legal') c = 'Юридическое лицо и ИП'


    let d = ''
    el.address.forEach((v, index) => {
      if (v.place_type_name === "Область") d = d + ('обл.' + v.place_name + ', ')
      if (v.place_type_name === "Город") d = d + ('г.' + v.place_name)
      if (v.place_type_name === "Улица") d = d + (', ул.' + v.place_name)
      if (v.place_type_name === "Проспект") d = d + (', пр-т.' + v.place_name)
      if (v.place_type_name === "Переулок") d = d + (', пер.' + v.place_name)
      if (v.place_type_name === "Бульвар") d = d + (', б-р.' + v.place_name)
      if (v.place_type_name === "Дом") d = d + (', д.' + v.place_name)
      if (v.place_type_name === "Квартира") d = d + (', кв.' + v.place_name)
    })

    return (
      <div style={(showPanel === i) ? { backgroundColor: '#E7EAEF' } : undefined} key={i + el.sensor_number} className={s.itemT}
        onClick={() => {
          setshowPanel(i)
          dispatch(getIdReducer({ id: data.data[i].parameter_id, resourse: data.data[i].resource }))
        }}>
        <div className={s.promptWrapper}>

          {a}
          {e}

        </div>

        <p className={s.t3}>{d}</p>
        <p className={s.t1} style={{ color: b[1] }}>{b[0]}</p>
        <p className={s.t1}>{el.sensor_number}</p>
        <p className={s.t2}>{el.last_seen && el.last_seen.split('T')[0]}</p>
        <p className={s.t2}>{el.last_value}</p>
        <p className={s.t1}>{'m3'}</p>
        <p className={s.t1}></p>
        <p className={s.t2}>{c}</p>


      </div>
    )
  })
  return (
    <div style={isloader ? { opacity: '0.3' } : undefined} className={s.pages} >
      <div className={s.title}>
        <h2>Приборы</h2>
        <div className={s.titleFilter}>
          <TfiFilter size={25} onClick={() => { showFilter(prev => !prev) }} />
        </div>

      </div>
      {(showPanel !== undefined) && <PanelRight close={() => setshowPanel(undefined)} />}
      <div className={s.table} style={showPanel ? { opacity: '0.7' } : undefined}>
        <div className={s.headerT}>
          <div className={s.t0}>Cтатус</div>
          <p className={s.t3}>Адрес</p>
          <p className={s.t1}>Тип</p>
          <p className={s.t1}>Номер счетчика</p>
          <p className={s.t2}>Дата получения данных</p>
          <p className={s.t2}>Последнее показание</p>
          <p className={s.t1}>Единица измерения</p>
          <p className={s.t1}>Номер радиомодуля</p>
          <p className={s.t2}>Принадлежность</p>


        </div>
        {table}

        <NumberPages />
      </div>


    </div>
  )
}

export default FirstPages